import React from 'react'
import { graphql, withPrefix } from 'gatsby'
import Page, { Section } from '../shared/page-commons'
import RocketCursor from '../shared/rocket-cursor.js'
import Blur from '../shared/blur.js'

export default function SecretPage(props) {
  let modTime = 'just moment ago.'
  if (props.data.file) {
    modTime = props.data.file.modifiedTime
  }

  return (
    <Page bgColor="surface">
      <RocketCursor />
      <Section textColumn>
        <h1>Our Greatest Secrets</h1>
        <img
          src={withPrefix('/images/core/saruman.gif')}
          alt="You shall not pass!"
        />
        <p>This page was compiled at {modTime}</p>
        <p css="padding-top:50px">
          Please hover over the following text to reveal a secret of ESA that
          not many people are aware of...
        </p>

        <Blur>
          <h3 css="padding-top:20px">We are not NASA!</h3>
        </Blur>
      </Section>
    </Page>
  )
}

export const query = graphql`
  query CompileTime {
    file(absolutePath: { glob: "**/data/compile_log.txt" }) {
      id
      name
      modifiedTime
    }
  }
`
